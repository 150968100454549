/* eslint-disable max-len */
import React, { useState } from 'react';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import Layout from '../../components/layout/Layout';
import image4 from '../../assets/images/news/la-barclays.jpg';
import play from '../../assets/images/icons/play.svg';
import image1 from '../../assets/images/news/preview-leg.png';
import image2 from '../../assets/images/news/preview-fib.png';
import image3 from '../../assets/images/news/fib-video-preview.jpg';
import VideoPlayer from '../../components/common/VideoPlayer';
import twitter from '../../assets/images/bx_bxl-twitter.svg';
import facebook from '../../assets/images/bx_bxl-facebook.svg';
import linkedin from '../../assets/images/bx_bxl-linkedin.svg';
import video from '../../assets/videos/fibonacci-home.mp4';

function TransformingMulti() {
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);
  return (
    <Layout
      title="News about us"
      description="LawAdvisor was founded in 2015 in Melbourne, Australia with a fearless and ambitious belief: that everybody should have access to justice."
      image="/metaimages/la-barclays.jpg"
    >
      <VideoPlayer
        open={videoPlayerOpen}
        onClose={() => setVideoPlayerOpen(false)}
        video={video}
      />
      <div className="articles-section">
        <div className="fit-width titleSection">
          <h2>LawAdvisor launches new solutions in collaboration with Barclays and leading law firms to revolutionise the practice of law</h2>
          <span className="mb-0 text-muted">January 26th, 2021</span>
        </div>
        <div>
          <img
            src={image4}
            className="full-width"
            alt="logo"
          />
        </div>
        <div className="fit-width contentSection">
          <p>
            <b>London, UK - January 26th, 2021</b>
            {' '}
            - Today legal technology specialist LawAdvisor, has launched two
            innovative new legal technology solutions, aimed at transforming the way law firms work with
            corporate in-house legal departments and legal matters are managed.
          </p>
          <p>
            The company has worked closely with a steering group spearheaded by Barclays and composed of over 10 leading international law firms.
            Barclays played an integral role in the development of the new platforms,
            leading efforts to bring lawyers and legal innovation specialists together with LawAdvisor to create
            industry-leading technology that addresses specific issues facing today’s legal teams.
          </p>
          <p>
            The first of the new products to be launched is LegalEye, a platform that enables in-house teams and
            law firms to better track and manage their interactions, derive actionable insights from upcoming
            and existing engagements, and facilitate better knowledge sharing within the industry. As a result of
            this approach, both parties are able drive more meaningful and value-driven relationships.
          </p>
          <p>
            The LegalEye platform brings law firms and in-house legal functions together into an ecosystem for
            sharing, collaboration, insights and diversity. Highly configurable and flexible, this platform supports
            digital workflows for all aspects of operations from RFP and instruction to legal project management
            and financial tracking.
          </p>
          <p>
            Early projections indicate that the platform could save lawyers, from both in-house and law firms,
            40-60% of the time they spend managing tenders, depending on the complexity of the matter.
          </p>
          <p>
            Having played a major role in its development, Barclays is now in the process of onboarding six of
            the firms on its legal panel to LegalEye, with the remainder of firms to follow in March 2021.
          </p>
          <p>
            <b>Paul Branscombe, Legal Change Director at Barclays, said:</b>
            {' '}
            <i>
              “We believe we are leading in our
              approach to working with outside counsel and we are committed to continuing to improve and
              develop ever more collaborative and efficient ways to work together. Our work with LawAdvisor on
              LegalEye is an exciting leap forward for us and our law firms that creates efficiencies for us all and
              streamlines the way we work. Technology is driving significant transformation in all areas of the legal
              industry and Barclays are proud to be continuing to drive this innovation.”
            </i>
          </p>
          <div className="fit-width">
            <img src={image1} alt="logo" />
          </div>
          <p>
            <b>Brennan Ong, founder and CEO of LawAdvisor, said:</b>
            {' '}
            <i>
              “In working with Barclays and leading law
              firms, we sought to create a solution which would cater to the needs of corporate legal departments,
              and benefit the wider industry. It is clear from the demand we have seen, that the solution speaks to
              the heart of the problems that in-house departments face. Barclays has been a leading innovator
              among corporate legal departments for many years, and the expertise now ingrained in the platform
              is testament to Barclays’ advanced external engagement and panel firm management processes,
              which all legal teams will soon be able to benefit from.”
            </i>
          </p>
          <p>
            The second product LawAdvisor are launching this year is Fibonacci, a no-code legal project and
            matter management system that provides in-house counsel and law firms with the tools to execute
            of all kinds of legal matters, projects and transactions.
          </p>
          <p>
            It enables teams to build project plans, create and automate playbooks, discuss changes, create and
            manage documents and track progress with all stakeholders in a single workspace through a simple,
            intuitive user interface. Designed by lawyers and built by computer scientists, modules can be
            developed rapidly, without code, for all kinds of legal work from simple advisory to fast moving M&A
            and complex multi-jurisdictional litigation. Many such modules are already developed and adapted
            to the specific ways of working of the teams using them.
          </p>
          <div className="fit-width">
            <img src={image2} alt="logo" />
          </div>
          <p>
            Built with total flexibility in mind, Fibonacci allows teams to incorporate their own knowledge,
            expertise, processes, and ways of working into the platform to ensure teams - be they in-house or
            law firms - can leverage their experience, differentiate themselves from competitors through
            improved service delivery, and drive innovation. The platform is based on open standards, and is
            therefore able to combine all the data and tools that legal departments currently use, such as
            iManage, Microsoft Teams, Salesforce and Slack, consolidating information and arming lawyers with
            meaningful and actionable insights.
          </p>
          <p>
            Leading law firm Bird & Bird – a key member of the steering group that informed its development –
            is already reaping the benefits of Fibonacci.
          </p>
          <p>
            <b>David Kerr, CEO at Bird & Bird, said:</b>
            {' '}
            <i>
              “The legal sector has been crying out for new technology
              solutions that are both effective and not proprietary to any one firm. That’s exactly what LawAdvisor
              has built. They have a clear vision of how technology can transform the way we practice law, and
              crucially the legal and software expertise to realise that vision.
            </i>
          </p>
          <p>
            <i>
              “As a member of their steering group, we’ve worked closely with the LawAdvisor team to advise
              them of the legal project management challenges we’re facing across a diverse range of practice
              areas to ensure that the solutions they’re developing meet our needs and those of our valuable
              clients. The results of that collaboration are remarkable - we’re already using Fibonacci to great
              effect, and helping our clients receive better managed legal services that improves communication
              between all external and internal team members, from the most experienced to the newest recruit.
              Indeed, our clients are now of the view that law firms can truly become an extension of their own
              teams through the use of Fibonacci.
            </i>
          </p>
          <p>
            <i>
              “Fibonacci provides us with unrivalled transparency and improved collaboration between teams.
              With the automation of routine processes, along with real-time financial and status reports available
              at the click of a button, it generates significant efficiencies allowing lawyers to better focus on the
              substantive legal issues and improve our working relationship with clients.”
            </i>
          </p>
          <div className="fit-width">
            <div className="video-container">
              <div
                className="video"
                role="button"
                tabIndex="-1"
                onClick={() => {
                  setVideoPlayerOpen(true);
                }}
              >
                <div
                  className="play"
                >
                  <img src={play} className="icon" alt="play icon" />
                </div>
                <img src={image3} alt="logo" />
              </div>
            </div>
          </div>
          <p>
            With the launch of LegalEye and Fibonacci, LawAdvisor is bringing technology to market that takes
            account of the unique requirements of the legal sector and the complexities of multi-faceted case
            work and can transform the practice of law. Having served as both a software developer and a
            lawyer, founder and CEO Brennan Ong has been able to combine in-depth technical knowledge with
            a comprehensive understanding of the legal market, delivering transformative products tailored to
            lawyers’ needs.
          </p>
          <p>
            <b>Brennan Ong, founder and CEO of LawAdvisor, said:</b>
            {' '}
            <i>
              “Legaltech has received a lot of attention in
              recent years, but the reality is that lawyers are still overly reliant on legacy technologies like email
              and spreadsheets. At LawAdvisor we’re changing that and using our first-hand knowledge of the
              legal sector to build technologies that dramatically improve productivity and promote better
              relationships within legal teams.
            </i>
          </p>
          <p>
            <i>
              We’ve worked closely with a number of leading law firms and legal teams to ensure that these
              solutions are not only able to solve the problems they are facing today, but are capable of addressing
              challenges they may face in the future, as well as enabling teams to innovate as they grow and
              develop. I’m extremely grateful for the collaborative efforts that allowed us to bring these products
              to life, particularly Barclays for their trailblazing approach in involving their panel law firms in helping
              us conceptualise and create our new solutions.”
            </i>
          </p>
          <p>
            Related links:
            <br />
            <a href="https://www.reuters.com/article/legaltech-lawadvisor/lawadvisor-partners-with-barclays-law-firms-on-new-legal-ops-tools-idUSL1N2K10QN" target="_blank" rel="noreferrer">From reuters.com</a>
          </p>
        </div>
        <div className="social-container social">
          <div className="header">Like this article? Spread the word</div>
          <div className="logos">
            <LinkedinShareButton
              title="LawAdvisor launches new solutions in collaboration with Barclays and leading law firms to revolutionise the practice of law"
              url="https://www.lawadvisor.ventures/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays"
            >
              <div className="social-button">
                <img src={linkedin} alt="" />
              </div>
            </LinkedinShareButton>
            <TwitterShareButton
              title="LawAdvisor launches new solutions in collaboration with Barclays and leading law firms to revolutionise the practice of law"
              url="https://www.lawadvisor.ventures/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays"
            >
              <div className="social-button">
                <img src={twitter} alt="" />
              </div>
            </TwitterShareButton>
            <FacebookShareButton
              quote="LawAdvisor launches new solutions in collaboration with Barclays and leading law firms to revolutionise the practice of law"
              url="https://www.lawadvisor.ventures/news/lawadvisor-launches-new-solutions-in-collaboration-with-barclays"
            >
              <div className="social-button">
                <img src={facebook} alt="" />
              </div>
            </FacebookShareButton>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TransformingMulti;
